<template>
  <div class="policy">
    <div class="content">
      <div class="policy__title">
        Политика ООО «АстраЗенека Фармасьютикалз» в области обработки и
        обеспечения безопасности персональных данных
      </div>
      <div class="policy__body">
        <div class="body-popup">
          <div class="body-popup__container">
            <div class="body-popup__text text-normal">
              <p>
                Я (далее также – «Субъект») ознакомлен(а) с <a
                  href="https://astrazeneca.ru/confidentiality.html"
                  target="_blank"
                  >Политикой</a
                >
                ООО «АстраЗенека Фармасьютикалз» в области обработки
                и обеспечения безопасности персональных данных и добровольно
                предоставляю свои персональные данные в объёме, соответствующем
                объёму данных, внесённых в поля выше, а также даю своё согласие
                на обработку всех указанных в настоящем документе данных
                для целей:
              </p>

              <ul class="body-popup__text-list">
                <li>
                  предоставления научной медицинской и любой другой информации
                  о заболевании и/или о продукции компании «АстраЗенека»,
                  а также материалов в письменной, печатной, электронной,
                  аудио-, видео-, устной и любой другой соответствующей форме,
                  посредством электронной почты, обычной или курьерской почты,
                  телефонных звонков, СМС сообщений, лично и/или любым иным
                  соответствующим способом;
                </li>
                <li>
                  приглашения на научные, образовательные и иные мероприятия,
                  направленные на повышение профессионального уровня
                  соответствующих специалистов, всеми указанными выше способами
                  и во всех указанных выше формах;
                </li>
                <li>
                  формирования базы данных лиц, которые предоставили свои
                  персональные данные;
                </li>
                <li>
                  приглашения на участие в опросах и иных мероприятиях с целью
                  изучения профессионального мнения специалистов о заболеваниях
                  и/или подходах к терапии, а также удовлетворённости качеством
                  взаимодействия с представителями ООО «АстраЗенека
                  Фармасьютикалз».
                </li>
              </ul>

              <p>
                Я подтверждаю, что уведомлен(а) и предоставил(а) согласие
                на обработку моих персональных данных следующим оператором:
                ООО «АстраЗенека Фармасьютикалз» (ОГРН 1057749225830, Россия,
                город Москва, 123112, 1-й Красногвардейский проезд, дом 21,
                строение 1, этаж 30, комнаты 13 и 14), далее по тексту —
                Оператор.
              </p>
              <p>
                Обработка персональных данных Субъекта также производится
                следующими организациями, действующими по поручению
                ООО «АстраЗенека Фармасьютикалз»: АстраЗенека ЮК Лимитед,
                ООО «Воксис», АО «ТЁРН», ООО «Атос АйТи Солюшенс энд Сервисез»
                и другими организациями из списка, доступного по ссылке
                <a href="https://qr.short.az/Privacy_RU" target="_blank"
                  >https://qr.short.az/Privacy_RU</a
                >
                .
              </p>
              <p>
                Обработка персональных данных включает в себя следующие способы
                обработки с использованием средств автоматизации и/или
                без использования таких средств, а также смешанным способом,
                необходимых для достижения целей обработки данных, включая:
              </p>
              <ul class="body-popup__text-list">
                <li>
                  сбор, запись, систематизацию, накопление персональных данных
                  Оператором;
                </li>
                <li>
                  хранение, удаление (в электронном виде и на бумажном
                  носителе);
                </li>
                <li>
                  уточнение (обновление, изменение), извлечение, использование
                  персональных данных;
                </li>
                <li>
                  передачу персональных данных (распространение, предоставление,
                  доступ, в том числе в порядке, предусмотренном
                  законодательством РФ, по внутренней сети Оператора
                  и ответственным сотрудникам Оператора, а также по защищённым
                  каналам связи (на машинных носителях) в иные организации и/или
                  лицу, осуществляющие обработку персональных данных
                  по поручению Оператора;
                </li>
                <li>обезличивание, блокирование, уничтожение.</li>
              </ul>
              <p>
                Оператор также вправе обрабатывать персональные данные
                посредством внесения их в электронную базу данных, включения
                в списки (реестры) и отчётные формы, предусмотренные
                документами, регламентирующими предоставление отчётных данных
                (документов). Лицо, подписавшее настоящее Согласие, уведомлено
                и даёт своё согласие на трансграничную передачу данных
                в указанных целях, включая, но не ограничиваясь, на передачу
                данных на территорию Великобритании, Германии, Ирландии,
                Нидерландов.
              </p>

              <p>
                Настоящее Согласие может быть отозвано в любое время, любым
                из следующих способов:
              </p>

              <ul class="body-popup__text-list">
                <li>
                  в форме уведомления по адресу электронной почты
                  <a
                    href="mailto: privacyrussia@astrazeneca.com "
                    target="_blank"
                    >privacyrussia@astrazeneca.com</a
                  >
                </li>
                <li>
                  в форме письменного уведомления по адресу Оператора: 123100,
                  город Москва, 1-й Красногвардейский проезд, дом 21, строение
                  1, этаж 30, комнаты 13 и 14
                </li>
              </ul>

              <p>
                Настоящее Согласие действует до дня получения Оператором
                уведомления об отзыве Согласия.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Policy",
};
</script>

<style lang="scss" scoped>
.policy {
  padding: 60px 0;
  @media screen and (max-width: 767px) {
    padding: 30px 0;
  }
  &__title {
    margin-bottom: 24px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 29px;
    color: #830051;
    @media screen and (max-width: 767px) {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
    }
  }
  &__body {
    .body-popup {
      &__text {
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        p,
        ul {
          margin-bottom: 16px;
        }
        ul {
          list-style: disc;
          padding-left: 16px;
        }
        a {
          color: #da338c;
          text-decoration: underline;
        }
        &.text-bold {
          font-weight: 500;
        }
        &.text-normal {
          font-weight: 400;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  &__btn {
    margin-top: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    &-undo {
      margin-right: 16px;
      @media screen and (max-width: 767px) {
        width: 100%;
        margin-right: 0;
        margin-top: 12px;
      }
    }
    &-accept {
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    @media screen and (max-width: 767px) {
      flex-direction: column-reverse;
    }
  }
}
</style>